import React from "react";
import { Link } from "gatsby";
import * as styles from "./dropdownInput.module.css";
import DropdownMenu from "./dropdownMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export default class DropdownInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: this.getSelectedItem(),
        };
    }

    componentDidMount() {}

    // componentDidUpdate(prevProps, prevState) {
    //     if (this.props.selectedId !== prevProps.selectedId || this.props.selectedText !== prevProps.selectedText) {
    //         this.setState({
    //             selectedItem: this.getSelectedItem(),
    //         });
    //     }
    // }

    getSelectedItem = () => {
        let selectedItem = null;
        if (Array.isArray(this.props.options) && this.props.options.length > 0) {
            if (this.props.selectedId !== null) {
                let filter_res = this.props.options.filter((o) => {
                    return o.id === this.props.selectedId;
                });
                if (filter_res.length > 0) {
                    selectedItem = filter_res[0];
                } else {
                    selectedItem = this.props.options[0];
                }
            } else if (this.props.selectedText !== null) {
                let filter_res = this.props.options.filter((o) => {
                    return o.text === this.props.selectedText;
                });
                if (filter_res.length > 0) {
                    selectedItem = filter_res[0];
                } else {
                    selectedItem = this.props.options[0];
                }
            }
        }
        return selectedItem;
    };

    onClick = (selectedOption) => {
        this.setState(
            {
                selectedItem: selectedOption,
            },
            () => {
                if (this.props.id) {
                    this.props.onSelectionChange(this.props.id, selectedOption.id || selectedOption.text);
                } else {
                    this.props.onSelectionChange(selectedOption.id || selectedOption.text);
                }
            }
        );
    };

    render() {
        return (
            <div className={`${styles.container}`} style={this.props.style || {}}>
                <DropdownMenu
                    relativeSize={true}
                    buttonFlex={true}
                    fillWidth={true}
                    short={true}
                    pin={this.props.pin || "right"}
                    iconComponent={
                        <div className={styles.dropdown_input_component}>
                            {!!this.state.selectedItem && !!this.state.selectedItem.image && (
                                <div className={styles.selected_image}>
                                    <img src={this.state.selectedItem.image} />
                                </div>
                            )}
                            <div className={`${styles.selected_text} ${(!this.state.selectedItem || !this.state.selectedItem.image) && styles.no_image}`}>
                                <h5>{this.props.overrideText ? this.props.overrideText : this.state.selectedItem ? this.state.selectedItem.text : "-"}</h5>
                            </div>
                            <span className={styles.dropdown_button}>
                                <FontAwesomeIcon icon={faChevronDown} />
                            </span>
                        </div>
                    }
                    items={[
                        ...this.props.options.map((option) => {
                            return {
                                type: "item",
                                name: option.text,
                                description: option.description || null,
                                image: option.image,
                                loading: false,
                                disabled: false,
                                onClick: () => {
                                    this.onClick(option);
                                },
                            };
                        }),
                    ]}
                />
            </div>
        );
    }
}
