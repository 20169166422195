import React from "react";
import * as styles from "./option.module.css";
import DropdownMenu from "./dropdownMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faCheck, faSyncAlt } from "@fortawesome/free-solid-svg-icons";

export default class Option extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value || "Select A Value",
        };
    }

    check() {
        this.setState({
            checked: true,
        });
    }
    uncheck() {
        this.setState({
            checked: false,
        });
    }

    // Toggle the check value
    optionChanged = (item) => {
        this.setState(
            {
                value: item,
            },
            () => {
                this.props.onChanged(item);
            }
        );
    };

    render() {
        return (
            <div className={styles.wrapper}>
                <div className={styles.text_container}>
                    {!!this.props.header && this.props.header.length > 0 && <p className={styles.header}>{this.props.header}</p>}
                    {!!this.props.text && this.props.text.length > 0 && <p className={styles.text}>{this.props.text}</p>}
                </div>
                <div className={styles.button_container}>
                    <DropdownMenu
                        iconComponent={<span className={styles.selected_text}>{this.state.value}</span>}
                        relativeSize={true}
                        buttonFlex={true}
                        items={this.props.options.map((i) => {
                            return {
                                type: "item",
                                name: i.replace(/[^a-z0-9]/gi, " "),
                                onClick: () => {
                                    this.optionChanged(i);
                                },
                            };
                        })}
                    />
                </div>
            </div>
        );
    }
}
