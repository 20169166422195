import React from "react";
import * as styles from "./selectionBox.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class SelectionBox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    onClick = () => {
        if (this.props.disabled !== true) {
            this.props.onSelected && this.props.onSelected(this.props.symbol);
        }
    };

    render() {
        return (
            <button
                className={`
                ${styles.container} 
                ${this.props.selected ? styles.selected : ""} 
                ${this.props.disabled ? styles.disabled : ""}
            `}
                onClick={this.onClick}
            >
                {this.props.backgroundImage && (
                    <div className={styles.background}>
                        <img src={this.props.backgroundImage} />
                        <div className={styles.overlay} />
                    </div>
                )}
                <div className={styles.image_container}>
                    {this.props.image ? <img className={styles.image} src={this.props.image} /> : this.props.icon && <FontAwesomeIcon icon={this.props.icon} className={styles.icon} />}
                </div>
                <div className={styles.text_container}>
                    <span className={styles.text}>{this.props.text}</span>
                    {this.props.subtext && <span className={styles.subtext}>{this.props.subtext}</span>}
                </div>
            </button>
        );
    }
}
