import React from "react";
import axios from "axios";
import { navigate } from "gatsby";
import { withAuthentication } from "../../../hoc/withAuthentication";
import * as styles from "./index.module.css";
import Main from "../../../components/main";
import ScreenLoad from "../../../components/screenLoad";
import Button from "../../../components/button";
import Input from "../../../components/input";
import DropdownInput from "../../../components/dropdownInput";
import DropdownMenu from "../../../components/dropdownMenu";
import Checkbox from "../../../components/checkbox";
import ContentArea from "../../../components/contentArea";
import SocialInput from "../../../components/socialInput";
import SocialButton from "../../../components/socialButton";
import TextArea from "../../../components/textArea";
import Option from "../../../components/option";
import GenreSelector from "../../../components/genreSelector";
import SignOverlay from "../../../components/signOverlay";
import AccountVerifyOverlay from "../../../components/accountVerifyOverlay";
import SelectionBox from "../../../components/selectionBox";
import ToggleSwitch from "../../../components/toggleSwitch";
import NavPanel from "../../../components/navPanel";
import Consts from "../../../config/consts";
import Common from "../../../config/common";
import Provider from "../../../config/provider";
import Api from "../../../config/api";
import Util from "../../../config/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTimes, faCheck, faGears, faBars } from "@fortawesome/free-solid-svg-icons";
import bgroovia_tile_icon_500 from "../../../images/bgroovia_tile_icon_500.png";
import bminted_tile_icon_500 from "../../../images/bminted_tile_icon_500.png";
import bgroovia_tile_icon_small from "../../../images/bgroovia_tile_icon_small.png";
import bminted_tile_icon_small from "../../../images/bminted_tile_icon_small.png";

export default withAuthentication(
    {
        requiredAuthLevels: [Consts.AUTH_LEVELS.AUTHENTICATED],
        redirectPage: "/",
    },
    class EditProfile extends React.Component {
        // export default class Home extends React.Component {
        constructor(props) {
            super(props);
            this.main_ref = React.createRef();
            this.state = {
                initialising: true,
                saving: false,
                requestData: {},
                profile: null,

                hasUpdatedProfileImage: false,
                hasUpdatedAlias: false,
                hasUpdatedBio: false,
                hasUpdatedDisplayName: false,
                hasUpdatedPrivacy: false,
                hasUpdatedSocials: false,
                hasUpdatedCommsNewFollower: false,

                scheme: "BMINTED",
                alias: "",
                bio: "",
                socialLinks: [],

                commsNewFollower: false,

                profileImage: null,
                uploadedProfileImageKey: null,
                dataProfileImage: null,
                profileImageBase64: null,

                displayName: "",
                displayNameAvailability: null,
                isCheckingDisplayName: false,
                displayNameCheckTimer: null,
                error_displayname: null,

                requiresSigning: false,

                privacyContentCollectionsVisible: "PUBLIC",
                privacyContentCreatedVisible: "PUBLIC",
                privacyContentOwnedVisible: "PUBLIC",
                privacyFollowersVisible: "PUBLIC",
                privacyProfilePhotoVisible: "PUBLIC",
                privacySocialLinksVisible: "PUBLIC",

                privacyOptions: [
                    {
                        id: "PUBLIC",
                        text: "Public",
                    },
                    {
                        id: "PRIVATE",
                        text: "Private",
                    },
                    {
                        id: "FOLLOWERS",
                        text: "Followers",
                    },
                ],
            };
        }

        componentDidMount() {
            Api.market({
                endpoint: "/user/profile",
                method: "GET",
                data: {
                    displayName: this.props.params.displayname,
                },
            })
                .then((profile) => {
                    if (!profile.owned) {
                        navigate(`/u/${this.props.params.displayname}`);
                    } else {
                        this.setState({
                            initialising: false,
                            profile: profile,
                            scheme: profile.scheme,
                            displayName: profile.displayName,
                            alias: profile.alias,
                            bio: profile.bio,
                            socialLinks: profile.socialLinks,

                            profileImage: profile.profileImage,

                            privacyContentCollectionsVisible: Common.objectPropertyExists(profile, "privacy.contentCollectionsVisible", "PUBLIC"),
                            privacyContentCreatedVisible: Common.objectPropertyExists(profile, "privacy.contentCreatedVisible", "PUBLIC"),
                            privacyContentOwnedVisible: Common.objectPropertyExists(profile, "privacy.contentOwnedVisible", "PUBLIC"),
                            privacyFollowersVisible: Common.objectPropertyExists(profile, "privacy.followersVisible", "PUBLIC"),
                            privacyProfilePhotoVisible: Common.objectPropertyExists(profile, "privacy.profilePhotoVisible", "PUBLIC"),
                            privacySocialLinksVisible: Common.objectPropertyExists(profile, "privacy.socialLinksVisible", "PUBLIC"),

                            commsNewFollower: Common.objectPropertyExists(profile, "communication.newFollower", false),
                        });
                    }
                })
                .catch((e) => {
                    this.setState({
                        initialising: false,
                        profile: null,
                    });
                });
        }

        onHideSignOverlay = () => {
            this.setState({
                requiresSigning: false,
                saving: false,
                requestData: {},
            });
        };

        onImageSelectFile = () => {
            this.selectImage()
                .then((file) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = () => {
                        console.log(reader);
                        this.setState({
                            hasUpdatedProfileImage: true,
                            uploadedProfileImageKey: null,
                            dataProfileImage: file,
                            profileImageBase64: reader.result,
                        });
                    };
                })
                .catch((e) => {
                    Util.notify.error("Invalid file: " + e);
                });
        };
        selectImage = () => {
            return new Promise((resolve, reject) => {
                let input = document.createElement("input");
                input.type = "file";
                input.multiple = false;
                input.accept = "image/*";
                input.onchange = (_) => {
                    let files = Array.from(input.files);
                    if (files[0].size > 50000000) {
                        reject("File size too big.");
                    } else {
                        resolve(files[0]);
                    }
                };
                input.click();
            });
        };

        uploadProfileImage = () => {
            return new Promise((resolve, reject) => {
                if (!this.state.dataProfileImage) {
                    reject();
                } else if (this.state.uploadedProfileImageKey) {
                    resolve(this.state.uploadedProfileImageKey);
                } else {
                    //let contentType = this.state.base64DisplayImage.substring(5, this.state.base64DisplayImage.indexOf(";")).toLowerCase();
                    Api.market({
                        endpoint: "/content/upload/url/",
                        method: "GET",
                        data: {
                            contentType: this.state.dataProfileImage.type.toLowerCase(),
                            dataType: "USER_PROFILE_IMAGE",
                        },
                    })
                        .then((upload_res) => {
                            axios({
                                method: "PUT",
                                url: upload_res.url,
                                headers: {
                                    "Content-Type": this.state.dataProfileImage.type.toLowerCase(),
                                },
                                data: this.state.dataProfileImage,
                            })
                                .then(() => {
                                    this.setState(
                                        {
                                            uploadedProfileImageKey: upload_res.key,
                                        },
                                        () => {
                                            this.checkUploadStatus(upload_res.key, 20)
                                                .then(() => {
                                                    resolve(upload_res.key);
                                                })
                                                .catch((e) => {
                                                    reject();
                                                });
                                        }
                                    );
                                })
                                .catch(reject);
                        })
                        .catch(reject);
                }
            });
        };

        checkUploadStatus = (key, maxRetries, retryCount = 0) => {
            return new Promise((resolve, reject) => {
                Api.market({
                    endpoint: "/content/upload/status/",
                    method: "GET",
                    data: {
                        key,
                    },
                })
                    .then((status_res) => {
                        if (status_res.status !== "UPLOADED") {
                            if (maxRetries > retryCount) {
                                setTimeout(() => {
                                    this.checkUploadStatus(key, maxRetries, retryCount + 1)
                                        .then(resolve)
                                        .catch(reject);
                                }, 1000);
                            } else {
                                resolve(false);
                            }
                        } else {
                            resolve(true);
                        }
                    })
                    .catch(reject);
            });
        };

        commsNewFollowerChange = (id, switchedOn) => {
            this.setState({
                hasUpdatedCommsNewFollower: true,
                commsNewFollower: switchedOn,
            });
        };

        onPrivacyChange = (id, value) => {
            console.log(id, value);
            this.setState({
                hasUpdatedPrivacy: true,
                [id]: value,
            });
        };

        onDisplayNameUpdated = (text) => {
            clearTimeout(this.state.displayNameCheckTimer);

            // Run a basic check to confirm the displayname looks ok
            if (!text || text.length <= 0) {
                // Invalid displayname
                this.setState({
                    displayNameAvailability: null,
                    isCheckingDisplayName: false,
                });
            } else {
                // If we're not already set to 'checking' then lets set that now
                if (this.state.isCheckingDisplayName !== true) {
                    this.setState({
                        isCheckingDisplayName: true,
                        error_displayname: null,
                    });
                }

                // Wait for a second before we make a call to the back-end to check the displayname
                // adding a delay here allows for further changes to be made without us constantly
                // ambushing the back-end
                this.state.displayNameCheckTimer = setTimeout(() => {
                    // Make call to service to check the availability
                    this.setState(
                        {
                            displayName: text,
                            attention_displayname: false,
                            isCheckingDisplayName: true,
                        },
                        () => {
                            Api.market({
                                endpoint: "/user/verify/displayname",
                                method: "GET",
                                data: {
                                    displayName: text,
                                },
                            })
                                .then((res) => {
                                    console.log("Received response from checkDisplaynameAvailability", res);

                                    // Done - display outcome
                                    this.setState({
                                        isCheckingDisplayName: false,
                                        displayNameAvailability: res.availability,
                                        hasUpdatedDisplayName: res.availability === "NOT_TAKEN",
                                    });
                                })
                                .catch((e) => {
                                    // Failed call
                                    this.setState(
                                        {
                                            isCheckingDisplayName: false,
                                            displayNameAvailability: null,
                                        },
                                        () => {}
                                    );
                                });
                        }
                    );
                }, 500);
            }
        };
        onAliasUpdated = (text) => {
            this.setState({
                alias: text,
                hasUpdatedAlias: true,
            });
        };
        onBioUpdated = (text) => {
            this.setState({
                bio: text,
                hasUpdatedBio: true,
            });
        };
        onSocialsChanged = (socials) => {
            console.log(socials);
            this.setState({
                hasUpdatedSocials: true,
                socialLinks: socials,
            });
        };

        onSave = () => {
            let request_data = {};

            // Run through any updated details and create an object worth of
            // data we want to update
            if (this.state.hasUpdatedDisplayName) {
                request_data.newDisplayName = this.state.displayName;
            }
            if (this.state.hasUpdatedAlias) {
                request_data.alias = this.state.alias;
            }
            if (this.state.hasUpdatedBio) {
                request_data.bio = this.state.bio;
            }
            if (this.state.hasUpdatedSocials) {
                request_data.socialLinks = this.state.socialLinks;
            }
            if (this.state.hasUpdatedPrivacy) {
                request_data.socialLinksVisible = this.state.privacySocialLinksVisible;
                request_data.profilePhotoVisible = this.state.privacyProfilePhotoVisible;
                request_data.contentCreatedVisible = this.state.privacyContentCreatedVisible;
                request_data.contentOwnedVisible = this.state.privacyContentOwnedVisible;
                request_data.contentCollectionsVisible = this.state.privacyContentCollectionsVisible;
                request_data.followersVisible = this.state.privacyFollowersVisible;
            }
            if (this.state.hasUpdatedCommsNewFollower) {
                request_data.newFollowerCommunicationAllowed = this.state.commsNewFollower;
            }

            // Check we have something to update
            if (Object.keys(request_data).length <= 0 && !this.state.hasUpdatedProfileImage) {
                Util.notify.info("No changes found!");
            } else {
                this.setState({
                    saving: true,
                    requiresSigning: true,
                    requestData: request_data,
                });
            }
        };

        onSaveWithSignature = (signature) => {
            if ((!this.state.requestData || Object.keys(this.state.requestData).length <= 0) && this.state.hasUpdatedProfileImage !== true) {
                this.setState(
                    {
                        saving: false,
                        requiresSigning: false,
                        requestData: {},
                    },
                    () => {
                        Util.notify.info("Couldn't find anything to save! Please retry.");
                    }
                );
            } else {
                this.setState(
                    {
                        requiresSigning: false,
                    },
                    () => {
                        const onPatch = () => {
                            Api.market({
                                endpoint: "/user/profile",
                                method: "PATCH",
                                data: {
                                    ...this.state.requestData,
                                    displayName: this.props.params.displayname,
                                    signature,
                                },
                            })
                                .then(() => {
                                    if (this.state.hasUpdatedDisplayName) {
                                        window.location.reload();
                                    } else {
                                        this.setState(
                                            {
                                                hasUpdatedProfileImage: false,
                                                hasUpdatedAlias: false,
                                                hasUpdatedBio: false,
                                                hasUpdatedDisplayName: false,
                                                hasUpdatedPrivacy: false,
                                                hasUpdatedSocials: false,
                                                hasUpdatedCommsNewFollower: false,
                                                requestData: {},
                                            },
                                            () => {
                                                Util.notify.info("Saved Changes!");
                                            }
                                        );
                                    }
                                })
                                .catch((e) => {
                                    Util.notify.error("Failed to save changes, please retry or try again later.");
                                })
                                .finally(() => {
                                    this.setState({
                                        saving: false,
                                    });
                                });
                        };

                        // Did we update our profile image?
                        if (this.state.hasUpdatedProfileImage) {
                            Promise.all([this.uploadProfileImage()])
                                .then((upload_res) => {
                                    // Set our image key in the request
                                    this.state.requestData.profileImageKey = this.state.uploadedProfileImageKey;

                                    // Run the patch
                                    onPatch();
                                })
                                .catch((e) => {
                                    Util.notify.error(`Failed to update your profile at this time. Please retry or try again later.`);
                                    this.setState({
                                        saving: false,
                                    });
                                });
                        } else {
                            // Run the patch
                            onPatch();
                        }
                    }
                );
            }
        };

        render() {
            return (
                <Main
                    ref={this.main_ref}
                    title={"BMinted"}
                    initialising={this.state.initialising}
                    auth={this.props.auth}
                    prices={this.props.prices}
                    providers={this.props.providers}
                    noFooterMargin={true}
                    currentChain={this.props.currentChain}
                    chains={this.props.chains}
                >
                    <ContentArea extraTopPadding={true} centered={true} slim={true}>
                        <ContentArea header={"Profile Image."} bottomRule={true}>
                            <TextArea>
                                <div className={styles.profile_image_container}>
                                    <div className={styles.profile_image_left}>
                                        <div className={styles.profile_image}>
                                            {!!this.state.profileImageBase64 ? (
                                                <img src={this.state.profileImageBase64} />
                                            ) : (
                                                this.state.profileImage && <img src={`${process.env.GATSBY_STORAGE}user/${this.state.profileImage}`} />
                                            )}
                                        </div>
                                    </div>
                                    <div className={styles.profile_image_right}>
                                        <TextArea>
                                            <p>This image should be 500x500 in resolution and can be of filetype: .png, .gif or .jpg</p>
                                            <br />
                                            <Button displayMode={1} hoverMode={6} text={"Select Image"} style={{ margin: 0 }} onClick={this.onImageSelectFile} />
                                        </TextArea>
                                    </div>
                                </div>
                            </TextArea>
                            <br />
                        </ContentArea>

                        <ContentArea header={"Profile Details."} bottomRule={true}>
                            <TextArea header={"Display Name (Public)."}>
                                <p>
                                    Your display name must start and end with an a-z letter, or a number 0-9, you can also use special characters ("-", "_" or ".") as seperators but these must not
                                    follow another symbol.
                                </p>
                                <Input
                                    placeholder={"Enter your desired Display Name"}
                                    onTextChanged={this.onDisplayNameUpdated}
                                    id="diplayName"
                                    name="displayName"
                                    defaultValue={this.state.displayName}
                                    regex={/^[a-zA-Z0-9_.-]+$/g}
                                    maxLength={20}
                                    hideMaxLength={true}
                                    speechBubbleText={
                                        this.state.error_displayname
                                            ? this.state.error_displayname
                                            : this.state.isCheckingDisplayName
                                            ? "Checking..."
                                            : this.state.displayNameAvailability === "TAKEN"
                                            ? "Displayname is taken - try another!"
                                            : this.state.displayNameAvailability === "NOT_TAKEN"
                                            ? "Displayname available!"
                                            : this.state.displayNameAvailability === "INVALID"
                                            ? "Displayname is not valid"
                                            : null
                                    }
                                    speechBubbleIcon={
                                        this.state.error_displayname
                                            ? faTimes
                                            : this.state.isCheckingDisplayName
                                            ? faSpinner
                                            : this.state.displayNameAvailability === "TAKEN"
                                            ? faTimes
                                            : this.state.displayNameAvailability === "NOT_TAKEN"
                                            ? faCheck
                                            : this.state.displayNameAvailability === "INVALID"
                                            ? faTimes
                                            : null
                                    }
                                    speechBubbleIconRotate={this.state.isCheckingDisplayName}
                                />
                            </TextArea>
                            <br />

                            {this.state.scheme === "BGROOVIA" && (
                                <TextArea header={"Alias (Public)."}>
                                    <p>What do your fans know you as? This is the name we will display front and center on your profile.</p>
                                    <Input
                                        placeholder={"What do your fans know you as?"}
                                        onTextChanged={this.onAliasUpdated}
                                        id="diplayName"
                                        name="displayName"
                                        defaultValue={this.state.alias}
                                        maxLength={30}
                                        hideMaxLength={true}
                                    />
                                    <br />
                                </TextArea>
                            )}

                            <TextArea header={"Bio (Public)."}>
                                <p>Your Bio will appear on your profile screen under your Alias or DisplayName.</p>
                                <Input multiline={true} placeholder={"Tell us about yourself..."} onTextChanged={this.onBioUpdated} defaultValue={this.state.bio} maxLength={400} />
                            </TextArea>
                            <br />

                            <TextArea header={"Socials (Public)."}>
                                <p>Your social links will appear on your profile.</p>
                                <SocialInput socials={this.state.socialLinks} allowMultipleCustom={true} socialsChanged={this.onSocialsChanged} />
                            </TextArea>
                            <br />
                        </ContentArea>

                        <ContentArea header={"Personalisation."} bottomRule={true}>
                            <TextArea header={"Privacy Settings."}>
                                <p>
                                    Restrict the visibility of your profile. 'Public' visibility means that anyone using the platform will be able to view. 'Private' visibility means that only you can
                                    see the section. 'Followers' means that only other users who are following your account can view the section. Note that media could still be viewable directly.
                                </p>
                                <div className={styles.privacy_container}>
                                    <div className={styles.option_container}>
                                        <DropdownInput
                                            id={"privacyContentCollectionsVisible"}
                                            options={this.state.privacyOptions}
                                            selectedId={this.state.privacyContentCollectionsVisible}
                                            onSelectionChange={this.onPrivacyChange}
                                        />
                                    </div>
                                    <div className={styles.text_container}>
                                        <p>Who would you like your Collections to be visible to?</p>
                                    </div>
                                </div>
                                <div className={styles.privacy_container}>
                                    <div className={styles.option_container}>
                                        <DropdownInput
                                            id={"privacyContentCreatedVisible"}
                                            options={this.state.privacyOptions}
                                            selectedId={this.state.privacyContentCreatedVisible}
                                            onSelectionChange={this.onPrivacyChange}
                                        />
                                    </div>
                                    <div className={styles.text_container}>
                                        <p>Who would you like your Created Content to be visible to?</p>
                                    </div>
                                </div>
                                <div className={styles.privacy_container}>
                                    <div className={styles.option_container}>
                                        <DropdownInput
                                            id={"privacyContentOwnedVisible"}
                                            options={this.state.privacyOptions}
                                            selectedId={this.state.privacyContentOwnedVisible}
                                            onSelectionChange={this.onPrivacyChange}
                                        />
                                    </div>
                                    <div className={styles.text_container}>
                                        <p>Who would you like your Owned Content to be visible to?</p>
                                    </div>
                                </div>
                                <div className={styles.privacy_container}>
                                    <div className={styles.option_container}>
                                        <DropdownInput
                                            id={"privacyFollowersVisible"}
                                            options={this.state.privacyOptions}
                                            selectedId={this.state.privacyFollowersVisible}
                                            onSelectionChange={this.onPrivacyChange}
                                        />
                                    </div>
                                    <div className={styles.text_container}>
                                        <p>Who would you like your Followers to be visible to?</p>
                                    </div>
                                </div>
                                <div className={styles.privacy_container}>
                                    <div className={styles.option_container}>
                                        <DropdownInput
                                            id={"privacyProfilePhotoVisible"}
                                            options={this.state.privacyOptions}
                                            selectedId={this.state.privacyProfilePhotoVisible}
                                            onSelectionChange={this.onPrivacyChange}
                                        />
                                    </div>
                                    <div className={styles.text_container}>
                                        <p>Who would you like your Profile Photo to be visible to?</p>
                                    </div>
                                </div>
                                <div className={styles.privacy_container}>
                                    <div className={styles.option_container}>
                                        <DropdownInput
                                            id={"privacySocialLinksVisible"}
                                            options={this.state.privacyOptions}
                                            selectedId={this.state.privacySocialLinksVisible}
                                            onSelectionChange={this.onPrivacyChange}
                                        />
                                    </div>
                                    <div className={styles.text_container}>
                                        <p>Who would you like your Social Links to be visible to?</p>
                                    </div>
                                </div>
                            </TextArea>
                            <br />
                        </ContentArea>

                        <ContentArea header={"Communication."} bottomRule={false}>
                            <TextArea header={"New Followers."}>
                                <p>Would you like us to contact you when you receive a new follower?</p>
                                <ToggleSwitch id={"commsNewFollower"} text={"Allow New Follower Communications?"} switch={this.state.commsNewFollower} onSwitch={this.commsNewFollowerChange} />
                            </TextArea>
                            <br />
                        </ContentArea>
                        <br />
                        <br />
                    </ContentArea>

                    <NavPanel
                        showScrollTop={true}
                        extraBottomMargin={
                            this.state.hasUpdatedProfileImage ||
                            this.state.hasUpdatedAlias ||
                            this.state.hasUpdatedBio ||
                            this.state.hasUpdatedDisplayName ||
                            this.state.hasUpdatedPrivacy ||
                            this.state.hasUpdatedSocials ||
                            this.state.hasUpdatedCommsNewFollower
                        }
                    />

                    {(this.state.hasUpdatedProfileImage ||
                        this.state.hasUpdatedAlias ||
                        this.state.hasUpdatedBio ||
                        this.state.hasUpdatedDisplayName ||
                        this.state.hasUpdatedPrivacy ||
                        this.state.hasUpdatedSocials ||
                        this.state.hasUpdatedCommsNewFollower) && (
                        <div className={styles.saveBar}>
                            <div className={styles.inner}>
                                <Button displayMode={9} hoverMode={6} large={true} disabled={this.state.saving === true} text={"Save Changes"} onClick={this.onSave} style={{ margin: 0 }} />
                            </div>
                        </div>
                    )}

                    {this.state.requiresSigning && (
                        <SignOverlay
                            onSigned={this.onSaveWithSignature}
                            addresses={this.props.auth.user.addresses}
                            onClose={this.onHideSignOverlay}
                            currentChain={this.props.currentChain}
                            chains={this.props.chains}
                        />
                    )}
                </Main>
            );
        }
    }
);
