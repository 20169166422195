// extracted by mini-css-extract-plugin
export var background = "selectionBox-module--background--bddJI";
export var container = "selectionBox-module--container--FieW1";
export var disabled = "selectionBox-module--disabled--bLYYc";
export var icon = "selectionBox-module--icon--hx8lh";
export var image = "selectionBox-module--image--Bc52O";
export var image_container = "selectionBox-module--image_container--fv-Lc";
export var overlay = "selectionBox-module--overlay--hx2Xa";
export var selected = "selectionBox-module--selected--5-oxV";
export var subtext = "selectionBox-module--subtext--XHPhm";
export var text = "selectionBox-module--text--NnknF";
export var text_container = "selectionBox-module--text_container--2BL5r";